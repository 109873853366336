import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CartWindow from "./components/CartWindow";
import CheckoutPage from "./components/CheckoutPage";
import SignUp from "./pages/SignUp/SignUp";
import Vendor from "./pages/Vendor/Vendor";

const AppLayout = lazy(() => import("./Layouts/AppLayout/AppLayout"));
const OnboardLayout = lazy(() =>
  import("./Layouts/OnboardLayout/OnboardLayout")
);
const Home = lazy(() => import("./pages/Home"));
const NearbyVendors = lazy(() => import("./pages/NearbyVendors/NearbyVendors"));
const ProfileOverview = lazy(() =>
  import("./pages/ProfileOverview/ProfileOverview")
);
const Profile = lazy(() => import("./pages/Profile/Profile"));
const FavouriteStores = lazy(() =>
  import("./pages/FavouriteStores/FavouriteStores")
);
const TrendingProducts = lazy(() =>
  import("./pages/TrendingProducts/TrendingProducts")
);
const Categories = lazy(() => import("./components/Categories"));
const ProductsPage = lazy(() => import("./components/ProductsPage"));

const App = () => {
  const [isCartOpen, setIsCartOpen] = useState(false);

  return (
    <div className="min-w-[1024px] relative">
      <Suspense fallback={fb()}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={<AppLayout setIsCartOpen={setIsCartOpen} />}
            >
              <Route index element={<Home />} />
              <Route path="nearby-vendors" element={<NearbyVendors />} />
              <Route path="trending-products" element={<TrendingProducts />} />
              <Route path="checkout" element={<CheckoutPage />} />
              <Route path="categories" element={<Categories />} />
              <Route path="products/:categoryId" element={<ProductsPage />} />
              {/* Vendor/Supermarket */}
              <Route path="store/:store_id" element={<Vendor />} />
              {/* Favourite Vendors */}
              <Route path="favourite-stores" element={<FavouriteStores />} />

              {/* Profile */}
              <Route path="profile" element={<Profile />}>
                {/* Default component in profile page */}
                <Route index element={<ProfileOverview />} />
              </Route>
            </Route>
            <Route path="/sign-up" element={<OnboardLayout />}>
              <Route index element={<SignUp />} />
            </Route>
          </Routes>

          {isCartOpen && <CartWindow onClose={() => setIsCartOpen(false)} />}
        </Router>
      </Suspense>
    </div>
  );
};

function fb() {
  return (
    <h1 className="h-screen flex justify-center items-center text-2xl">
      Loading...
    </h1>
  );
}

export default App;
